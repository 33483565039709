<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12" md="4" sm="4" xs="12">
                    <div class="card card-custom card-stretch">
                        <div class="card-body pt-4">

                            <div class="d-flex align-items-center">
                                <div
                                class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
                                >
                                <div
                                    class="symbol-label"
                                    :style="{
                                    backgroundImage: `url(${userInfo.photo})`
                                    }"
                                ></div>
                                <i class="symbol-badge bg-success"></i>
                                </div>
                                <div>
                                    <a
                                        class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                                        >{{ datosUsuario.nombre }}</a
                                    >
                                    <div class="text-muted">
                                    
                                        <li v-for="rol in datosUsuario.Roles" :key="rol">
                                            {{ rol.replace("SCO$", "") }}
                                        </li>
                                
                                </div>
                                    <!-- <div class="mt-2">
                                        <a
                                        class="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                                        >Chat</a
                                        >
                                        <a
                                        class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
                                        >Follow</a
                                        >
                                    </div> -->
                                </div>
                            </div>
                            <!--end::User-->
                            <!--begin::Contact-->
                            <div class="py-9">
                                <div class="d-flex align-items-center justify-content-between mb-2">
                                    <span class="font-weight-bold mr-2">Usuario</span>
                                    <a class="text-muted text-hover-primary">{{
                                        datosUsuario.usuario
                                    }}</a>
                                </div>
                                <v-divider></v-divider>
                                <!-- <div class="d-flex align-items-center justify-content-between mb-2">
                                    <span class="font-weight-bold mr-2">Teléfono(s):</span>
                                    <span class="text-muted">{{
                                        datosUsuario.phone
                                    }}</span>
                                </div>
                                <v-divider></v-divider>
                                <div class="d-flex align-items-center justify-content-between mb-2">
                                    <span class="font-weight-bold mr-2">Institución</span>
                                    <span class="text-muted">{{
                                        datosUsuario.institucion ? datosUsuario.institucion : "N/A"
                                    }}</span>
                                </div>
                                <v-divider></v-divider>
                                <div class="d-flex align-items-center justify-content-between mb-2">
                                    <span class="font-weight-bold mr-2">Area</span>
                                    <span class="text-muted">{{
                                        datosUsuario.area ? datosUsuario.area : "N/A"
                                    }}</span>
                                </div>
                                <v-divider></v-divider>
                                <div class="d-flex align-items-center justify-content-between">
                                    <span class="font-weight-bold mr-2">Cargo</span>
                                    <span class="text-muted">{{
                                        datosUsuario.cargo ? datosUsuario.cargo : "N/A"
                                    }}</span>
                                </div>
                                <v-divider></v-divider> -->
                            </div>
                            <!--end::Contact-->

                            <!--begin::Nav-->
                            <div
                                class="navi navi-bold navi-hover navi-active navi-link-rounded"
                                role="tablist"
                            >
                                <div class="navi-item mb-2">
                                    <a
                                        class="navi-link py-4 active"
                                        @click="setActiveTab"
                                        style="cursor:pointer"
                                        data-tab="0"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-selected="false"
                                    >
                                        <span class="navi-icon mr-2">
                                        <span class="svg-icon">
                                            <inline-svg src="media/svg/icons/Design/Layers.svg" />
                                        </span>
                                        </span>
                                        <span class="navi-text font-size-lg">Inicio</span>
                                    </a>
                                </div>

                                <div class="navi-item mb-2 d-none">
                                    <a
                                        class="navi-link py-4"
                                        @click="setActiveTab"
                                        style="cursor:pointer"
                                        data-tab="1"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-selected="false"
                                    >
                                        <span class="navi-icon mr-2">
                                        <span class="svg-icon">
                                            <inline-svg src="media/svg/icons/General/User.svg" />
                                        </span>
                                        </span>
                                        <span class="navi-text font-size-lg">Actualizar Información</span>
                                    </a>
                                </div>

                                <!-- <div class="navi-item mb-2">
                                    <a
                                        class="navi-link py-4"
                                        @click="setActiveTab"
                                        style="cursor:pointer"
                                        data-tab="2"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-selected="false"
                                    >
                                        <span class="navi-icon mr-2">
                                        <span class="svg-icon">
                                            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
                                        </span>
                                        </span>
                                        <span class="navi-text font-size-lg">Account Information</span>
                                    </a>
                                </div> -->

                                <div class="navi-item mb-2">
                                    <a
                                        class="navi-link py-4"
                                        @click="setActiveTab"
                                        style="cursor:pointer"
                                        data-tab="2"
                                        data-toggle="tab"
                                        role="tab"
                                        aria-selected="false"
                                    >
                                        <span class="navi-icon mr-2">
                                        <span class="svg-icon">
                                            <inline-svg
                                            src="media/svg/icons/Communication/Shield-user.svg"
                                            />
                                        </span>
                                        </span>
                                        <span class="navi-text font-size-lg">Actualizar contraseña</span>
                                        <span class="navi-label">
                                       <!--  <span
                                            class="label label-light-danger label-rounded font-weight-bold"
                                            >5</span
                                        > -->
                                        </span>
                                    </a>
                                </div>


                            </div>
                            <!--end::nav-->

                        </div>
                        <!--fin:: card-body-->
                    </div>
                    <!--fin:: card-->
                </v-col>

                <v-col cols="12" md="8" sm="8" xs="12">

                    <b-tabs class="hide-tabs" v-model="tabIndex">
                        <!--Resumen de información-->
                        <b-tab active>

                            <!--begin::Card-->
                            <div class="card card-custom">
                                <!--begin::Header-->
                                <div class="card-header py-3">
                                <div class="card-title align-items-start flex-column">
                                    <h3 class="card-label font-weight-bolder text-dark">
                                    Información personal
                                    </h3>
                                    <span class="text-muted font-weight-bold font-size-sm mt-1"
                                    >Resumen de su información</span
                                    >
                                </div>
                               <!--  <div class="card-toolbar">
                                    <button
                                    type="reset"
                                    class="btn btn-success mr-2"
                                    @click="save()"
                                    ref="kt_save_changes"
                                    >
                                    Save Changes
                                    </button>
                                    <button type="reset" class="btn btn-secondary" @click="cancel()">
                                    Cancel
                                    </button>
                                </div> -->
                                </div>
                                <!--end::Header-->
                                
                                <!--begin::Body-->
                                <div class="card-body">

                                    <v-row> 
                                        <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Usuario</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.usuario}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>

                                    <v-row> 
                                        <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Nombres</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.nombres}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                        <v-col cols="12" md="6" sm="6" xs="12"  class="pb-0 pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Apellidos</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.apellidos}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                    </v-row>

                                     <v-row> 
                                        <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Correo</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.email ? datosUsuario.email : "N/A"}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                        <v-col cols="12" md="6" sm="6" xs="12"  class="pb-0 pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Teléfono(s)</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.telefonos ? datosUsuario.telefonos : "N/A"}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                    </v-row>

                                    <v-divider></v-divider>

                                    <v-row> 
                                        <v-col cols="12" md="12" sm="12" xs="12" class="pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Institución</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.institucion ? datosUsuario.institucion : "N/A"}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>

                                    <v-row> 
                                        <v-col cols="12" md="6" sm="6" xs="12" class="pb-0 pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Área</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.area ? datosUsuario.area : "N/A"}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                        <v-col cols="12" md="6" sm="6" xs="12"  class="pb-0 pb-0">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-h6 black--text">Cargo</v-list-item-title>
                                                    <v-list-item-subtitle>{{datosUsuario.cargo ? datosUsuario.cargo : "N/A"}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>

                                    </v-row>
                                </div>
                            </div>
                            <!--end:: v-card-->

                            <!-- <KTProfileOverview></KTProfileOverview> -->
                        </b-tab>


                        <!--Actualizacion de datos personales-->
                        <b-tab class="d-none">
                            <!--begin::Card-->
                            <div class="card card-custom">
                                <!--begin::Header-->
                                <div class="card-header py-3">
                                <div class="card-title align-items-start flex-column">
                                    <h3 class="card-label font-weight-bolder text-dark">
                                    Información personal
                                    </h3>
                                    <span class="text-muted font-weight-bold font-size-sm mt-1"
                                    >Actualice su información personal</span
                                    >
                                </div>
                               <!--  <div class="card-toolbar">
                                    <button
                                    type="reset"
                                    class="btn btn-success mr-2"
                                    @click="save()"
                                    ref="kt_save_changes"
                                    >
                                    Save Changes
                                    </button>
                                    <button type="reset" class="btn btn-secondary" @click="cancel()">
                                    Cancel
                                    </button>
                                </div> -->
                                </div>
                                <!--end::Header-->
                                
                                <!--begin::Body-->
                                <div class="card-body">

                                     <v-form
                                        ref="form"
                                        v-on:submit.prevent="actualizarUsuario"
                                        v-model="validForm"
                                        >

                                        <v-row class="mt-4">
                                            <v-col md="6" sm="6" xs="12" class="pt-1 pb-0">
                                                <v-text-field
                                                    autocomplete="off"
                                                    class="required"
                                                    dense
                                                    filled
                                                    v-model="datosUsuario.nombres"
                                                    label="Nombres"
                                                    :rules="[required('nombres del usuario')]"
                                                    maxlength="100"
                                                ></v-text-field>
                                                </v-col>

                                                <v-col md="6" sm="6" xs="12" class="pt-1 pb-0">
                                                <v-text-field
                                                    autocomplete="off"
                                                    class="required"
                                                    dense
                                                    filled
                                                    v-model="datosUsuario.apellidos"
                                                    label="Apellidos"
                                                    :rules="[required('apellidos del usuario')]"
                                                    maxlength="100"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>

                                        
                                        <v-row>

                                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                            <v-text-field
                                                
                                                autocomplete="off"
                                                class="required"
                                                dense
                                                filled
                                                v-model="datosUsuario.email"
                                                label="Correo electrónico"
                                                :rules="[required('Correo usuario'), validEmail('')]"
                                                :disabled="accion === 2"
                                                maxlength="100"
                                            ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-1 pb-1">
                                            <v-autocomplete
                                                v-model="datosUsuario.institucionesId"
                                                :items="instituciones"
                                                class="required"
                                                dense
                                                filled
                                                :loading="ddInstitucionesLoading"
                                                label="Institución"
                                                :item-text="item => item.sigla ? `${item.nombreInstitucion} (${item.sigla})` : item.nombreInstitucion"
                                                item-value="id"
                                                :rules="[selectRequired('institución')]"
                                            ></v-autocomplete>
                                            </v-col>
                                        </v-row>


                                        <v-row>
                                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                                <v-text-field
                                                    v-model="datosUsuario.area"
                                                    class="required"
                                                    dense
                                                    filled
                                                    label="Área"
                                                    :rules="[required('área'), minLength('área', 2), maxLength('área', 30)]"
                                                    maxlength="30"
                                                ></v-text-field>
                                            </v-col>


                                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                                <v-text-field
                                                    v-model="datosUsuario.cargo"
                                                    class="required"
                                                    dense
                                                    filled
                                                    label="Cargo"
                                                    :rules="[required('cargo'), minLength('cargo', 2), maxLength('cargo', 30)]"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>

                                        

                                        <v-row>
                                            <v-col md="12" sm="12" class="pt-1 pb-1">
                                            <v-spacer></v-spacer>
                                            
                                            <!--:disabled="!validDocForm" type="submit"-->
                                            <v-btn
                                                color="light-blue-502"
                                                class="white--text mb-2 float-right"
                                                type="submit"
                                                :elevation="0"
                                                :disabled="!validForm"
                                                :loading="btnRegistroLoading"
                                            >
                                                Actualizar información
                                            </v-btn>

                                           <!--  <v-btn
                                                color="grey lighten-5"
                                                elevation="0"
                                                class="mb-2 float-right grey lighten-5 mr-1"
                                                @click="
                                                dialog = false;
                                                resetForm();
                                                "
                                                :disabled="btnRegistroLoading"
                                            >
                                                Cancelar
                                            </v-btn> -->
                                            </v-col>

                                        </v-row>

                                     </v-form>

                                </div>
                                <!--end::Body-->
                            
                            </div>
                        </b-tab>


                        <!--inicio:: actualizar contraseña-->

                        <b-tab>

                            <!--begin::Card-->
                            <div class="card card-custom">
                                <!--begin::Header-->
                                <div class="card-header py-3">
                                <div class="card-title align-items-start flex-column">
                                    <h3 class="card-label font-weight-bolder text-dark">
                                    Contraseña
                                    </h3>
                                    <span class="text-muted font-weight-bold font-size-sm mt-1"
                                    >Actualice su contraseña para el inicio de sesión</span
                                    >
                                </div>
                               <!--  <div class="card-toolbar">
                                    <button
                                    type="reset"
                                    class="btn btn-success mr-2"
                                    @click="save()"
                                    ref="kt_save_changes"
                                    >
                                    Save Changes
                                    </button>
                                    <button type="reset" class="btn btn-secondary" @click="cancel()">
                                    Cancel
                                    </button>
                                </div> -->
                                </div>
                                <!--end::Header-->
                                
                                <!--begin::Body-->
                                <div class="card-body">

                                    <v-form
                                        ref="form"
                                        v-on:submit.prevent="actualizarPass"
                                        v-model="validFormPass"
                                        >


                                        <v-row>
                                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                                <v-text-field
                                                    v-model="datosPass.passActual"
                                                    class="required"
                                                    dense
                                                    type="password"
                                                    filled
                                                    label="Contraseña actual"
                                                    :rules="[required('contraseña actual'), minLength('contraseña actual', 8), maxLength('contraseña actual', 30)]"
                                                    maxlength="30"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>

                                        <v-row>
                                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                                <v-text-field
                                                    v-model="datosPass.passNuevo"
                                                    class="required"
                                                    dense
                                                    type="password"
                                                    filled
                                                    @input="checkPassword"
                                                    label="Nueva contraseña"
                                                    :rules="[required('nueva contraseña'),minLength('nueva contraseña', 8), maxLength('nueva contraseña', 30)]"
                                                    maxlength="30"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="12">
                                                <ul class="urules">
                                                    <li v-bind:class="{ is_valid: contains_eight_characters }">8 caracteres</li>
                                                    <li v-bind:class="{ is_valid: contains_number }">Contener al menos un número</li>
                                                    <li v-bind:class="{ is_valid: contains_uppercase }">Una letra mayúscula</li>
                                                    <li v-bind:class="{ is_valid: contains_special_character }">Caracter especial $, %, @, #</li>
                                                </ul>
                                            </v-col>


                                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                                <v-text-field
                                                    v-model="datosPass.confirmacionPassNuevo"
                                                    class="required"
                                                    dense
                                                    @input="validarNuevoPass"
                                                    type="password"
                                                    filled
                                                    label="Confirmar nueva contraseña"
                                                    :rules="[required('confirmar contraseña'),minLength('confirmar contraseña', 8), maxLength('confirmar contraseña', 30)]"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>

                                        <v-row>
                                            <v-col md="12" sm="12" class="pt-1 pb-1">
                                            <v-spacer></v-spacer>
                                            
                                            <!--:disabled="!validDocForm" type="submit"-->
                                            <v-btn
                                                color="light-blue-502"
                                                class="white--text mb-2 float-right"
                                                type="submit"
                                                :elevation="0"
                                                :disabled="!validFormPass || !valid_password || !confirmadoPass"
                                                :loading="btnRegistroPassLoading"
                                            >
                                                Actualizar Contraseña
                                            </v-btn>

                                            </v-col>

                                        </v-row>


                                    </v-form>

                                </div>
                            </div>
                            <!--end:: v-card -->
                            
                        </b-tab>

                        <!--finesto:: actualizar contraseña-->

                        <b-tab>
                           
                        </b-tab>

                       
                    </b-tabs>
                </v-col>
            </v-row>
        </v-container>

        <!--Inicio:: Snack alert-->
        <SnackAlert ref="snackalert"></SnackAlert>
        <!-- Fin:: Snack alert-->

        <!--Dialog loader -->
        <DialogLoader
        :dialogVisible="dialogLoaderVisible"
        :text="dialogLoaderText"
        ></DialogLoader>
        <!---->
    </div>
</template>
<script>
//import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { OBTENER_PERFIL_USUARIO_ADSIS, ACTUALIZAR_PASS_USUARIO_PERFIL } from "@/core/services/store/usuarios/usuario.module";

import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";


import validations from "@/core/untils/validations.js";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import { mapGetters } from "vuex";


export default {
    name: "PerfilUsuario",
    components: {
        SnackAlert,
        DialogLoader
    },
    data() {
        return {
            tabIndex: 0,
            datosUsuario:{
                nombres: "",
                apellidos: "",
                email: "",
                institucionesId: 0,
                area: "",
                cargo: ""
            },
            datosPass: {
                passActual: "",
                passNuevo: "",
                confirmacionPassNuevo: ""
            },
            confirmadoPass: false,
            error: false,
            dialogLoaderVisible: false,
            dialogLoaderText: "",
            ddInstitucionesLoading: false,
            validForm: false,
            btnRegistroLoading:false,
            validFormPass: false,
            btnRegistroPassLoading: false,
            accion: 1,
            password_length: 0,
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            valid_password: false,
            userInfo: {
                photo: "media/users/blank.png"
            },
            ...validations
        };
    },

    methods:{

        setActiveTab(event) {
            let target = event.target;
            if (!event.target.classList.contains("navi-link")) {
                target = event.target.closest(".navi-link");
            }

            const tab = target.closest('[role="tablist"]');
            const links = tab.querySelectorAll(".navi-link");
            // remove active tab links
            for (let i = 0; i < links.length; i++) {
                links[i].classList.remove("active");
            }

            // set clicked tab index to bootstrap tab
            this.tabIndex = parseInt(target.getAttribute("data-tab"));

            // set current active tab
            target.classList.add("active");
        },
        

        //Obtener entidades o instituciones
        async obtenerEntidades() {
            this.instituciones = [];
            this.ddInstitucionesLoading = true;
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: `Entidades/all/1`})
            .then(res => {
            if (res.status === 200) {
                this.instituciones = res.data;
            }
            this.ddInstitucionesLoading = false;
            })
            .catch(() => {
                this.instituciones = [];
                this.ddInstitucionesLoading = false;
            });
        },


        //Obtener datos usuario
        async obtenerDatosUsuario(){
            //let token = JwtService.getToken();
       
            /* this.dialogLoaderText = "Obteniendo información del usuario...";
            this.dialogLoaderVisible = true;
            this.usuario = [];
            this.nombreUsuario =""; */
            this.datosUsuario= {};
            //this.institucionesId=0;
            await this.$store
                .dispatch(OBTENER_PERFIL_USUARIO_ADSIS,{nick: this.currentUser.usuario })
                .then(res => {
                    if (res.status === 200) {
                   /*  this.modalTitle = "Actualizar Usuario";
                    this.registrado = true; */
                    /* this.usuario = res.data;
                    this.accion=2;
                    this.nombreUsuario =  this.usuario.nombres + ` ` + this.usuario.apellidos; */

                    this.datosUsuario = {
                        nombres: res.data.nombres,
                        nombre: res.data.nombre,
                        usuario: res.data.usuario,
                        apellidos: res.data.apellidos ? res.data.apellidos : "",
                        email: res.data.email ? res.data.email : "",
                        institucionesId: res.data.institucionId ? res.data.institucionId : 0,
                        area: res.data.area ? res.data.area : "",
                        cargo: res.data.cargo ? res.data.cargo : ""   
                    };
                } else {
                    this.error=true;
                }
            })
            .catch(() => {
                this.usuario = {};
                this.registrado = false;
                this.dialogLoaderVisible = false;
                this.accion=1;
            });

        },

        checkPassword() {
            this.password_length = this.datosPass.passNuevo.length;
                    const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
                    
            if (this.password_length > 7) {
                this.contains_eight_characters = true;
            } else {
                this.contains_eight_characters = false;
                    }
                    
            this.contains_number = /\d/.test(this.datosPass.passNuevo);
            this.contains_uppercase = /[A-Z]/.test(this.datosPass.passNuevo);
                    this.contains_special_character = format.test(this.datosPass.passNuevo);
            
            if (this.contains_eight_characters === true &&
                            this.contains_special_character === true &&
                            this.contains_uppercase === true &&
                            this.contains_number === true) {
                                this.valid_password = true;			
            } else {
                this.valid_password = false;
            }

            //console.log(this.valid_password);
        },

        validarNuevoPass(){
            if(this.datosPass.passNuevo == this.datosPass.confirmacionPassNuevo){
                this.confirmadoPass = true;
            }
            else{
                this.confirmadoPass = false;
            }
        },


        async actualizarUsuario(){

        },
        
        async actualizarPass(){
            this.btnRegistroPassLoading = true;
            this.dialogLoaderText = "Actualizando contraseña...";
            this.dialogLoaderVisible = true;
            this.datosPass.usuario = this.datosUsuario.usuario;
            
            await this.$store
            .dispatch(ACTUALIZAR_PASS_USUARIO_PERFIL, {datos: this.datosPass})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.datosPass.passActual = "";
                    this.datosPass.passNuevo = "";
                    this.datosPass.confirmacionPassNuevo = "";
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                 this.btnRegistroPassLoading = false;
                   this.dialogLoaderVisible = false;
            })
            .catch(() => {
                this.btnRegistroPassLoading = false;
                  this.dialogLoaderVisible = false;
                this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro.`);
            });
        }


    },

    created(){
        this.obtenerEntidades();
        this.obtenerDatosUsuario();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Mi perfil" }]);
    },

    computed: {
        ...mapGetters(["currentUser"]),
    }
    
}
</script>
<style lang="scss">

    
    .urules
    {
        li { 
            margin-bottom: 8px;
            color: #525f7f;
            position: relative;

            &:before {
                content: "";
                width: 0%; height: 2px;
                background: #2ecc71;
                position: absolute;
                left: 0; top: 50%;
                display: block;
                transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }

            &.is_valid {
                &:before { width: 100%; }
            }
        }
       
    }

    .is_valid { color: rgba(136, 152, 170, 0.8); }
    
    
</style>